import React, { useState } from "react";
import getCsrfToken from "./CSRF";


export default function NewTeamForm(props) {

  const [teamName, setTeamName] = useState('');


  async function createNewTeam(credentials) {
    return fetch('/api/ccreatenewteam', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
      body: JSON.stringify(credentials)
//   body: credentials
    })
    .then(data => {
      return data.json();
    })
  }


  function handleChange(e) {
    setTeamName(e.target.value);
  }


  const handleSubmit = async e => {
    e.preventDefault();

    const token = await createNewTeam({
      "teamName" : teamName
    });
    console.log(token);

    props.setNewTeam(props.newTeam + 1);
    props.setView('c-settings');
    setTeamName("");
  }

  return (
    <form onSubmit={handleSubmit}>
      <label for="teamname" class="block text-sm font-medium text-gray-700">
        New Team Name
      </label>
      <div class="mt-1">
        <input type="text" name="text"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={teamName}
          onChange={handleChange}
        />
      </div>

      <div class="mt-2 flex justify-center">
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="submit"
        >
          Create New Team
        </button>
      </div>
    </form>
  );
}

