import { ReactMediaRecorder } from "react-media-recorder";
import { useState, useEffect, useRef } from 'react';
import getCsrfToken from "./CSRF";


const VideoPreview = (props) => {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && props.stream) {
      videoRef.current.srcObject = props.stream;
    }
  },[props.stream])
 if (!props.stream) {
    return null;
  }

  return (
      <video ref={videoRef} width={300} autoPlay />
  );
}



async function upload(mediaBlobUrl, props) {
  //turn the url into the object, then formdata it.
  console.log(mediaBlobUrl);

  async function uploadVideo(formVideo) {
    return fetch('/api/upload', {
      method: 'POST',
      headers: {
        'X-CSRFToken': await getCsrfToken()
      },
      body: formVideo
    })
    .then(data => {
      return data.json();
    })
  }


  const mediaBlob = await fetch(mediaBlobUrl)
    .then(response => response.blob());

  const video = new File(
    [mediaBlob],
    "video.mp4",
    { type: 'video/mp4' }
  );

  const formData = new FormData();

  formData.append('file', video);
  const result = await uploadVideo(formData);

  console.log(result);
  props.setView('p-videos');

}


export default function VideoForm(props) {

  const [videoView, setVideoView] = useState('none');

  return (
    <ReactMediaRecorder
      askPermissionOnMount={true}
      video={{facingMode: {ideal: 'environment'}}}
      audio={false}
      render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream}) => (
        <div>
          {status}
          <div>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {
                clearBlobUrl();
                startRecording();
                setVideoView('live');
              }}>
              Start Recording
            </button>&nbsp;
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {
                stopRecording();
                setVideoView('recorded');
              }}>
              Stop Recording
            </button>&nbsp;
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => upload(mediaBlobUrl, props)}>
              Upload
            </button>
          </div>
          {videoView === 'recorded' &&
            <div>
              <p>Recorded Video</p>
              <video src={mediaBlobUrl} width={300} controls autoPlay />
            </div>
          }
          {videoView === 'live' &&
            <div>
              <p>Live Preview</p>
              <VideoPreview stream={previewStream} />
            </div>
          }
        </div>
      )}
    />
  );
}

