import React, { useState } from "react";



 // eslint-disable-next-line

//import Form from "./components/Form";
//import FilterButton from "./components/FilterButton";

//chante
import CTeams from "./components/CTeams";
import CPlayers from "./components/CPlayers";
import CVideos from "./components/CVideos";
import CVideo from "./components/CVideo";
import CSettings from "./components/CSettings";


import AddPlayerForm from "./components/AddPlayerForm";
import InviteNewPlayerForm from "./components/InviteNewPlayerForm";
import NewTeamForm from "./components/NewTeamForm";
import DeleteTeamForm from "./components/DeleteTeamForm";
import JoinTeamForm from "./components/JoinTeamForm";
import CLeaveTeamForm from "./components/CLeaveTeamForm";
import FormNewPassword from "./components/FormNewPassword";



import FormLogin from "./components/FormLogin";
import FormSignup from "./components/FormSignup";
import LandingTest from "./components/LandingTest";

import PVideos from "./components/PVideos";
import PVideo from "./components/PVideo";
import PSettings from "./components/PSettings";
import PLeaveTeamForm from "./components/PLeaveTeamForm";


import UploadVideoForm from "./components/UploadVideoForm";
import VideoForm from "./components/VideoForm";

//videorecroder with camera selection under test
import VF2 from "./components/VF2";
//annotation testing
// eslint-disable-next-line
import AnnoVid from "./components/AnnoVid";







function App(props) {



  //landing, team, player, video, (also, settings, invite/select an existing player, create team)
  const [view, setView] = useState('landing');

  const [team, setTeam] = useState('none');
  const [player, setPlayer] = useState('none');
  const [video, setVideo] = useState('none');

  const [login, setLogin] = useState(false);
  const [loginTested, setLoginTested] = useState(false);

  const [username, setUsername] = useState('');

  const [newTeam, setNewTeam] = useState(0);
  const [newPlayer, setNewPlayer] = useState(0);
  const [newVideo, setNewVideo] = useState(0);


  function logout() {
    fetch("/api/logout")
    .then(a => {
//      console.log(a);
      setLogin(false);
    });
  }



  if(!login){

    if(!loginTested){
      return(
        <LandingTest
          setView={setView}
          setLogin={setLogin}
          setUsername={setUsername}
          setLoginTested={setLoginTested}
        />
      );
    } else {
      return(
        <>
        <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="mx-auto h-full max-w-3xl">
            <div class="flex h-screen flex-col justify-center ">

              <FormLogin
                setView={setView}
                setLogin={setLogin}
              />

            </div>
          </div>
        </div>
        </>
      );
    }

/*
            <div>
              <p onClick={() => {setView('TEST'); setLogin(true)}}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p onClick={() => {setView('TEST'); setLogin(true)}}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>

            </div>
*/




  } else if(view === 'signup'){
    return (
      <FormSignup
        setView={setView}
        setLogin={setLogin}
      />
    );
  } else if(view === 'landing'){
    return (
      <LandingTest
        setView={setView}
        setLogin={setLogin}
        setUsername={setUsername}
      />
    );
  } else if(view === 'c-landing'){
    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Welcome, Coach {username}</h2>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => setView('c-teams')}
            >
              View Your Teams
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => setView('c-settings')}
            >
              Settings
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
    );

/*
         <li>
           <div className="btn-group">
            <button type="button" className="btn" onClick={() => setView('invite')}>
              Send Invite
            </button>
          </div>
         </li>
*/
  } else if(view === 'p-landing'){
    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Welcome, {username}</h2>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => setView('p-videos')}
            >
              View Your Videos
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => setView('p-settings')}
            >
              Settings
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('captureVideo')}}
            >
              Capture Video
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('uploadVideo')}}
            >
              Upload a Video
            </button>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'c-teams'){

    //viewing a list of teams, click on one to see a list of players
    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Your Teams</h2>
           <CTeams
              setTeam={setTeam}
              setView={setView}
              newTeam={newTeam}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'c-settings'){
    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Settings</h2>
            <CSettings
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'c-new-password'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>New Password</h2>
            <FormNewPassword
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'c-new-team'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Create New Team</h2>
            <NewTeamForm
              newTeam={newTeam}
              setNewTeam={setNewTeam}
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-delete-team'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Delete Team</h2>
            <DeleteTeamForm
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-join-team'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Join Team</h2>
            <JoinTeamForm
              newTeam={newTeam}
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-leave-team'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Leave Team</h2>
            <CLeaveTeamForm
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-players'){

    //viewing a list of players in a team, click on one to see a list of videos

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>The players in {team.name}</h2>
            <CPlayers
              setPlayer={setPlayer}
              setView={setView}
              team={team}
              newPlayer={newPlayer}
              setNewPlayer={setNewPlayer}
            />
            <AddPlayerForm
              team={team}
              newPlayer={newPlayer}
              setNewPlayer={setNewPlayer}
            />
            <InviteNewPlayerForm
              team={team}
              newPlayer={newPlayer}
              setNewPlayer={setNewPlayer}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-teams'); setTeam('none');}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-videos'){ //removed justify center, large list breaks format

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center gap-8">
            <h2>{player.name}'s Videos</h2>
            <CVideos
              setVideo={setVideo}
              setView={setView}
              player={player}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-players'); setPlayer('none');}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'p-videos'){ //removed justify center, large list breaks format

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center gap-8">
            <h2>Your Videos</h2>
            <PVideos
              setVideo={setVideo}
              setView={setView}
              newVideo={newVideo}
              setNewVideo={setNewVideo}
           />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'p-settings'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Settings</h2>
            <PSettings
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'p-new-password'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>New Password</h2>
            <FormNewPassword
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );
  } else if(view === 'p-leave-team'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Leave Team</h2>
            <PLeaveTeamForm

            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-settings')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'uploadVideo'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Upload a Video</h2>
            <UploadVideoForm
              setView={setView}
            />

            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'captureVideo'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <h2>Capture Video</h2>
            <VideoForm
              setView={setView}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-landing')}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'c-video'){


    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <CVideo
              video={video}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('c-videos'); setVideo('none');}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'p-video'){

    return (
    <>
      <div class="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto h-full max-w-3xl">
          <div class="flex h-screen flex-col items-center justify-center gap-8">
            <PVideo
              video={video}
            />
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {setView('p-videos'); setVideo('none');}}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
    );

  } else if(view === 'TEST'){

    return (
      <div className="videoapp stack-large">
        <h1>TEST</h1>
        <ul
          className="video-list stack-large stack-exception"
          aria-labelledby="list-heading"
        >

          {/*<AnnoVid />*/}
          <VF2 />

          <li>
            <div className="btn-group">
              <button type="button" className="btn" onClick={() => {setView('landing')}}>
                Cancel
              </button>
            </div>
          </li>

        </ul>
      </div>
    );

  } else {
    //default go away page
     return (
      <div className="videoapp stack-large">
        <h1>Sorry, this doesn't work yet</h1>
        <ul
          className="video-list stack-large stack-exception"
          aria-labelledby="list-heading"
        >
          <li>
           <div className="btn-group">
            <button type="button" className="btn" onClick={() => setView('landing')}>
              Return to landing
            </button>
          </div>
         </li>
         <li>
           <div className="btn-group">
            <button type="button" className="btn" onClick={() => logout()}>
              Logout
            </button>
          </div>
         </li>
        </ul>
      </div>
    );
  }

}

export default App;
