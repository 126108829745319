import React, { useState, useEffect } from "react";

export default function LandingTest(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/type")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          if(result.user === "none"){
            props.setLogin(false);
            props.setView("landing");
          } else if(result.user === "coach"){
            props.setLogin(true);
            props.setView("c-landing");
            props.setUsername(result.username);
          } else if(result.user === "player"){
            props.setLogin(true);
            props.setView("p-landing");
            props.setUsername(result.username);
          } else if(result.type === "error"){
            console.log("user is neither coach nor player");
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [props])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {

    return <></>;
/*
    return <div>Loading...</div>;
*/
  } else {
    props.setLoginTested(true);

    return <></>;
/*
    return (
      <>
        <p>ERROR Type</p>
        <ul
          className="video-list stack-large stack-exception"
          aria-labelledby="list-heading"
        >
         <li>
           <div className="btn-group">
            <button type="button" className="btn" onClick={() => props.setLogin(false)}>
              Logout
            </button>
          </div>
         </li>
        </ul>

      </>
    );
*/
  }
}

