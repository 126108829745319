import React, { useState, useEffect, useRef } from "react";

const DrawCanvas = (props) => {

  const [drawing, setDrawing] = useState(false);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  const [first, setFirst] = useState(true);

  const [mousePos, setMousePos] = useState({ x:0, y:0 });
  const [lastPos, setLastPos] = useState(mousePos);

// Import, state and ref events
useEffect(() => {
  const canvas = canvasRef.current;
  // Setting the context to enable us draw
  const ctx = canvas.getContext('2d');
  ctxRef.current = ctx;
}, []);


const drawStart = (e) => {

  setDrawing(true);
  if(first) {
    canvasRef.current.height = canvasRef.current.offsetHeight;
    canvasRef.current.width = canvasRef.current.offsetWidth;
    ctxRef.current.lineCap = 'round';
    ctxRef.current.strokeStyle = 'blue';
    ctxRef.current.lineWidth = 2;
    setFirst(false);
  }

  setLastPos(getMousePos(canvasRef.current, e));
  ctxRef.current.beginPath();
};

const mD = ({ nativeEvent }) => {
  drawStart(nativeEvent);
};

// Set up touch events for mobile, etc
const tS = ({ nativeEvent }) => {
  setMousePos(getTouchPos(canvasRef.current, nativeEvent));
  let touch = nativeEvent.touches[0];
  drawStart({
    clientX: touch.clientX,
    clientY: touch.clientY
  });
};



const drawEnd = (e) => {
  setDrawing(false);
  ctxRef.current.closePath();
};

const mU = ({ nativeEvent }) => {
  drawEnd(nativeEvent);
};

const tE = ({ nativeEvent }) => {
  drawEnd(nativeEvent);
};



const drawMove = (e) => {
  setMousePos(getMousePos(canvasRef.current, e));
  if (drawing) {
    ctxRef.current.moveTo(lastPos.x, lastPos.y);
    ctxRef.current.lineTo(mousePos.x, mousePos.y);
    ctxRef.current.stroke();
    setLastPos(mousePos);
  }
};

const mM = ({ nativeEvent }) => {
  drawMove(nativeEvent);
};

const tM = ({ nativeEvent }) => {
  let touch = nativeEvent.touches[0];
  drawMove({
    clientX: touch.clientX,
    clientY: touch.clientY
  });
};


function getMousePos(canvasDom, mouseEvent) {
  var rect = canvasDom.getBoundingClientRect();
  return {
    x: mouseEvent.clientX - rect.left,
    y: mouseEvent.clientY - rect.top
  };
};

// Get the position of a touch relative to the canvas
function getTouchPos(canvasDom, touchEvent) {
  let rect = canvasDom.getBoundingClientRect();
  return {
    x: touchEvent.touches[0].clientX - rect.left,
    y: touchEvent.touches[0].clientY - rect.top
  };
}

    return (
      <>
        <canvas
          id="vCanvas"
          style={{position: 'absolute', width: '100%', height: '100%', top:'0px'}}
          onMouseDown={mD}
          onMouseUp={mU}
          onMouseMove={mM}

          onTouchStart={tS}
          onTouchEnd={tE}
          onTouchMove={tM}

          ref={canvasRef}
        />
      </>
    );
}

export default function CVideo(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [video, setVideo] = useState();

  const [progress, setProgress] = useState(0);

  const [paused, setPaused] = useState(true);
  const [playrate, setPlayrate] = useState(1);

  const [wasPaused, setWasPaused] = useState(false);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/videos/" + props.video.id)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setVideo(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [props])

const vPlayPause = () => {
  let vid = document.getElementById("vPlayer");

  if(vid.paused) {
    vid.play();
    setPaused(false);
  } else {
    vid.pause();
    setPaused(true);
  }
};

const vChangeRate = () => {
  let vid = document.getElementById("vPlayer");
  let speed = document.getElementById("speed").value;
  setPlayrate(speed);
  vid.playbackRate = speed;
};

/*
//it would be better to adjust the movement based on the playback speed. playback*5sec
const vBack = () => {
  let vid = document.getElementById("vPlayer");
  let ct = vid.currentTime;

  if (ct - 5 > 0) {
    vid.currentTime = ct - 5;
  } else {
    vid.currentTime = 0;
  }
};
const vForward = () => {
  let vid = document.getElementById("vPlayer");
  let ct = vid.currentTime;

  if (ct + 5 < vid.duration) {
    vid.currentTime = ct + 5;
  } else {
    vid.currentTime = vid.duration;
  }
};
*/

const vBlue = () => {
  const canvas = document.getElementById("vCanvas");
  const ctx = canvas.getContext('2d');

  ctx.strokeStyle = 'blue';
};
const vRed = () => {
  const canvas = document.getElementById("vCanvas");
  const ctx = canvas.getContext('2d');

  ctx.strokeStyle = 'red';
};
const vBlack = () => {
  const canvas = document.getElementById("vCanvas");
  const ctx = canvas.getContext('2d');

  ctx.strokeStyle = 'black';
};
const vClear = () => {
  const canvas = document.getElementById("vCanvas");
  const ctx = canvas.getContext('2d');

  ctx.clearRect(
    0,
    0,
    canvas.width,
    canvas.height
  );
};

const handleProgress = () => {

  let vid = document.getElementById("vPlayer");
  const duration = vid.duration;
  const currentTime = vid.currentTime;
  const progress = (currentTime / duration) * 100;
  setProgress(progress);
};

const changeBar = () => {
  let vid = document.getElementById("vPlayer");
  let bar = document.getElementById("pbar");

  setProgress(bar.value);

  vid.currentTime = bar.value/100 * vid.duration;
};

const needPause = () => {

  let vid = document.getElementById("vPlayer");

  if(!vid.paused) {
    vid.pause();
    setPaused(true);
    setWasPaused(true);
  }
};

const wasPlaying = () => {

  let vid = document.getElementById("vPlayer");
  if(wasPaused) {
    vid.play();
    setWasPaused(false);
  }
};

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

//no delete for me
    return (
<div class="pt-16">
  <div>
    <div class="relative w-full">
      <video class="max-h-[50vh] w-full" playsinline="" id="vPlayer" onTimeUpdate={handleProgress}>
        <source src={video.source} type="video/mp4"/>
      </video>
      <DrawCanvas
      />
    </div>
    <div class="mt-4">
      <input id="pbar" type="range" min="0" max="100" defaultValue="0" class="w-full" step="0.1"
        onChange={changeBar}
        onMouseDown={needPause}
        onTouchStart={needPause}
        onMouseUp={wasPlaying}
        onTouchEnd={wasPlaying}
        value={progress}
      />
    </div>

    <div class="mt-4">
      <label for="speed">Speed ({playrate}x)</label>
      <input id="speed" type="range" min="0.125" max="1" defaultValue="1" class="w-full" step="0.125" onChange={vChangeRate}/>
    </div>

    <div class="mt-4 flex justify-between gap-2">
      <button class="rounded-full bg-gray-400 p-3 text-white" onClick={vPlayPause}>
        {!paused &&
        <span>

          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </span>
        }
        {paused &&
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </span>
        }
      </button>

      <button class="rounded-full bg-black p-3 text-white" onClick={vBlack}>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
        </svg>
      </button>

      <button class="rounded-full bg-cyan-400 p-3 text-white" onClick={vBlue}>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
        </svg>
      </button>

      <button class="rounded-full bg-red-500 p-3 text-white" onClick={vRed}>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
        </svg>
      </button>
      <button class="rounded-full bg-gray-400 p-3 text-white" onClick={vClear}>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
      </button>
    </div>

  </div>
</div>
    );
  }
}
