import { ReactMediaRecorder } from "react-media-recorder";
import { useState, useEffect, useRef } from 'react';
import getCsrfToken from "./CSRF";


const VideoPreview = (props) => {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && props.stream) {
      videoRef.current.srcObject = props.stream;
    }
  },[props.stream])
 if (!props.stream) {
    return null;
  }

  return (
      <video ref={videoRef} width={300} autoPlay />
  );
}

async function upload(mediaBlobUrl) {
  //turn the url into the object, then formdata it.
  console.log(mediaBlobUrl);

  async function uploadVideo(formVideo) {
    return fetch('/api/upload', {
      method: 'POST',
      headers: {
        'X-CSRFToken': await getCsrfToken()
      },
      body: formVideo
    })
    .then(data => {
      return data.json();
    })
  }


  const mediaBlob = await fetch(mediaBlobUrl)
    .then(response => response.blob());

  const video = new File(
    [mediaBlob],
    "video.mp4",
    { type: 'video/mp4' }
  );

  const formData = new FormData();

  formData.append('file', video);
  const result = await uploadVideo(formData);
  console.log(result);
}


function vHC() {

  console.log('test');
}


async function changeCamera(setCamera){

  const cameraId = document.querySelector('.video-options>select').value;
  const newCam =  {
                    deviceId: {exact: cameraId}
                  };

//  console.log(newCam);
  setCamera(newCam);
}


async function populateCameras(){

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput');

/*
  console.log(navigator.mediaDevices.getSupportedConstraints());
  videoDevices.forEach((device => {
    console.log(device);
//    console.log(device.getCapabilities());
  }))
*/

  const options = videoDevices.map((videoDevice, index) => {
    let label;
    if(videoDevice.label === ''){
      let id;
      if (videoDevice.deviceId.length > 8) {
        id = videoDevice.deviceId.slice(0,4) + '...' + videoDevice.deviceId.slice(videoDevice.deviceId.length - 4);
      } else {
        id = videoDevice.deviceId
      }
      label = `no label ${id}`;
    } else {
      label = videoDevice.label;
    }
    return `<option value="${videoDevice.deviceId}">${label}</option>`;
  });

  if(options.length === 0) {
    options[0] = `<option value="">No cameras found</option>`
  }
  const cameraOptions = document.querySelector('.video-options>select');
  cameraOptions.innerHTML = options.join('');
}



async function PC2(setCameraList){

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput');


  const options = videoDevices.map((videoDevice, index) => {
    let label;

    console.log(videoDevice);

    if(videoDevice.label === ''){
      let id;
      if (videoDevice.deviceId.length > 8) {
        id = videoDevice.deviceId.slice(0,4) + '...' + videoDevice.deviceId.slice(videoDevice.deviceId.length - 4);
      } else {
        id = videoDevice.deviceId
      }
      label = `no label ${id}`;
    } else {
      label = videoDevice.label;
    }
    return <option value={videoDevice.deviceId}>{label}</option>;
  });

  if(options.length === 0) {
    options[0] = <option value="">No cameras found</option>
  }
//  const cameraOptions = document.querySelector('.video-options>select');
//  cameraOptions.innerHTML = options.join('');

//  console.log(options);

  setCameraList(options);

}



export default function VF2() {

  const [videoView, setVideoView] = useState('none');
  const [camera, setCamera] = useState(
    {
      facingMode: {ideal: 'environment'}
    }
  );
  const [cameraList, setCameraList] = useState(<option value="">Select camera</option>);





  return (
    <ReactMediaRecorder
      askPermissionOnMount={true}
      video={camera}
      audio={false}
      render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream}) => (
        <div>

          <div class="video-options">
            <button class="btn" type="button"
              onClick={populateCameras}>
              Populate Cameras
            </button>
            <button class="btn" type="button"
              onClick={() => changeCamera(setCamera)}>
              Set Camera
            </button>
            <select name="" id="" class="custom-select" onChange={vHC}>
              {cameraList}
            </select>
          </div>


          {status}
          <div>
            <button class="btn btn-outline-success"
              onClick={() => {
                PC2(setCameraList);
                clearBlobUrl();
                startRecording();
                setVideoView('live');
              }}>
              Start Recording
            </button>
            <button class="btn btn-outline-success"
              onClick={() => {
                stopRecording();
                setVideoView('recorded');
              }}>
              Stop Recording
            </button>
            <button class="btn btn-outline-success"
              onClick={() => upload(mediaBlobUrl)}>
              Upload
            </button>
          </div>
          {videoView === 'recorded' &&
            <div>
              <p>Recorded Video</p>
              <video src={mediaBlobUrl} width={300} controls autoPlay />
            </div>
          }
          {videoView === 'live' &&
            <div>
              <p>Live Preview</p>
              <VideoPreview stream={previewStream} />
            </div>
          }
        </div>
      )}
    />
  );
}






