export default async function getCsrfToken() {

  let _csrfToken;
    const response = await fetch(`/api/csrf/`, {
      credentials: 'include',
    });
    const data = await response.json();
    _csrfToken = data.csrfToken;

  return _csrfToken;
}
