import React, { useState } from "react";
import getCsrfToken from "./CSRF";


export default function FormSignup(props) {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();


  const [badLogin, setBadLogin] = useState(false);
  const [badPassword2, setBadPassword2] = useState(false);

async function createUser(credentials) {
 return fetch('/api/newuser', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
     'X-CSRFToken': await getCsrfToken()
   },
   body: JSON.stringify(credentials)
 })
   .then(data => {
     return data.json();
   })
   .then(response => {
     if(response.result === "OK"){
       props.setLogin(true);
       props.setView("landing");
       setBadLogin(false);
       setBadPassword2(false);
     } else {
       setBadLogin(true);
     }
   });
}



  const handleSubmit = async e => {
    e.preventDefault();

    const credentials = {
      "username": username,
      "email": username,
      "password": password,
      "password2": password2
    };

    const token = await createUser(credentials);

    console.log(token);
  }


  return (
    <form onSubmit={handleSubmit}>
      <h2 className="label-wrapper">
        <label className="label__lg">
          Sign up
        </label>
      </h2>
      <p>
      <label>
        Email Address: 
        <input
          type="text"
          className="input"
          onChange={e => setUsername(e.target.value)}
        />
      </label>
      </p>
      <p>
      <label>
        Password: 
        <input
          type="password"
          className="input"
          onChange={e => setPassword(e.target.value)}
        />
      </label>
      </p>
      <p>
      <label>
        Verify Password:
        <input
          type="password"
          className="input"
          onChange={e => setPassword2(e.target.value)}
          onKeyUp={e => {
             if (password !== password2) {
               setBadPassword2(true);
             } else {
               setBadPassword2(false);
             }
          }}

        />
      </label>
      </p>


      {badPassword2 &&
        <p>Passwords must match</p>
      }
      {badLogin &&
        <p>Bad Username or Password</p>
      }
      <button type="submit" className="btn btn__primary btn__lg">
        Sign up
      </button>

    </form>
  );
}
