import React, { useState } from "react";
import getCsrfToken from "./CSRF";


export default function UploadVideoForm(props) {

  const [video, setVideo] = useState();
  const [madeVideo, setMadeVideo] = useState(false);

  const [statusThing, setStatusThing] = useState('');
  const [intervalHolder, setIntervalHolder] = useState(false);
  const [dots, setDots] = useState('');

  async function uploadVideo(formVideo) {
    return fetch('/api/upload', {
      method: 'POST',
      headers: {
//        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
//      body: JSON.stringify(video)
      body: formVideo
    })
    .then(data => {
      return data.json();
    })
//    .then((result) => {
//      console.log('Success:', result);
//    })
//    .catch((error) => {
//      console.error('Error:', error);
//    });
  }




  function setupUpload() {
    if (intervalHolder !== false){
      clearInterval(intervalHolder);
      setIntervalHolder(false);
      setDots('');
   }

    setStatusThing('Uploading');

    const interval = setInterval(() => {
      console.log('interval');
      setDots(dots => {
        if (dots === '') {
          setDots('.');
        } else if (dots === '.') {
          setDots('..');
        } else if (dots === '..') {
          setDots('...');
        } else if (dots === '...') {
          setDots('.');
        }
      });
    }, 1000);

    setIntervalHolder(interval);
  }


  function handleChange(e) {
    setVideo(e.target.files[0]);
    setMadeVideo(true);
  }


  const handleSubmit = async e => {
    e.preventDefault();

    setupUpload();

    const formData = new FormData();
    formData.append('file', video);
    const token = await uploadVideo(formData);
    console.log(token);


    clearInterval(intervalHolder);
    setIntervalHolder(false);

    props.setView('p-videos');
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        className="input"
        name="file"
        autoComplete="off"
        onChange={handleChange}
      />
        {madeVideo &&
          <p>{video.name}  {video.type} {video.size}bytes</p>
        }
      <button
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        type="submit"
      >
        Upload Video
      </button>
      {statusThing}{dots}
    </form>
  );
}

