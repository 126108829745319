import React, { useState } from "react";
import getCsrfToken from "./CSRF";

export default function FormLogin(props) {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [badLogin, setBadLogin] = useState(false);

async function loginUser(credentials) {
 return fetch('/api/login/', {
   method: 'POST',
   headers: {
//     'Content-Type': 'application/json',
     'X-CSRFToken': await getCsrfToken()
   },
//   body: JSON.stringify(credentials)
   body: credentials
 })
   .then(data => {
     if(data.redirected){
       props.setLogin(true);
       props.setView("landing");
       setBadLogin(false);
     } else {
       setBadLogin(true);
     }
     return data.redirected
   })
}





  const handleSubmit = async e => {
    e.preventDefault();

    const credentials  = new FormData();

    credentials.append("username", username.toLowerCase());
    credentials.append("password", password);

    const token = await loginUser(credentials);

    console.log(token);
  }


  return (
    <form onSubmit={handleSubmit}>

      <h2>Login</h2>
      <label for="name" class="block text-sm font-medium text-gray-700">
        Email Address
      </label>
      <div class="mt-1">
        <input type="text" name="name" id="name"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setUsername(e.target.value)}
        />
      </div>

      <label for="password" class="block text-sm font-medium text-gray-700">
        Password
      </label>
      <div class="mt-1">
        <input type="password" name="password" id="password"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      {badLogin &&
        <p>Bad Username or Password</p>
      }

      <div class="mt-2 flex justify-end">
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="submit"
        >
          Login
        </button>
      </div>

      <div class="mt-2 flex justify-end">
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="button"
          onClick={() => {
            props.setLogin(true);
            props.setView("signup");
          }}
        >
          Signup
        </button>
      </div>

    </form>
  );
}
