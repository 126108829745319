import React, { useState } from "react";
import getCsrfToken from "./CSRF";


export default function AddPlayerForm(props) {

  const [player, setPlayer] = useState('');


  async function addPlayer(item) {
    return fetch('/api/caddplayer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
      body: JSON.stringify(item)
//   body: credentials
    })
    .then(data => {
      return data.json();
    })
  }


  function handleChange(e) {
    setPlayer(e.target.value);
  }


  const handleSubmit = async e => {
    e.preventDefault();

    const token = await addPlayer({
      "team": props.team.id,
      "playername" : player
    });
    console.log(token);

    props.setNewPlayer(props.newPlayer + 1);
    setPlayer("");
  }




 return (
    <form onSubmit={handleSubmit}>
      <label for="playername" class="block text-sm font-medium text-gray-700">
        Player Name
      </label>
      <div class="mt-1">
        <input type="text" name="playername"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={player}
          onChange={handleChange}
        />
      </div>

      <div class="mt-2 flex justify-center">
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="submit"
        >
          Add Existing Player
        </button>
      </div>
    </form>
  );
}

