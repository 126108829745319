import React, { useState, useEffect } from "react";
import getCsrfToken from "./CSRF";

export default function PVideos(props) {


  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [videos, setVideos] = useState([]);




  async function deleteVideo(credentials) {

    return fetch('/api/upload', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
      body: JSON.stringify(credentials)
    })
    .then(data => {
      props.setNewVideo(props.newVideo + 1);
      return data.json();
    })

  }



  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/videos")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);


          result = result.sort( (a,b) => a.date < b.date );
          result = result.map((e,i,a) => {
            e.date = e.date.slice(5) + '-' + e.date.slice(0,4);
            if(i === 0) {
              e.showdate = true;
              return e;
            } else if (e.date === a[i-1].date) {
              e.showdate = false;
              return e;
            } else {
              e.showdate = true;
              return e;
            }
          });

          setVideos(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [props])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {


 return (
      <>
        {videos.map(video => (
          <>
          {video.showdate ? <h2>{video.date}</h2> : <></>}
          <div class="mt-2 flex justify-between" key={video.id}>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {props.setVideo(video); props.setView('p-video')}}
            >
              {video.name}
            </button>&nbsp;

            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {deleteVideo({"id": video.id})}}
            >
              Delete
            </button>
          </div>
          </>
        ))}
      </>
    );
  }

}

