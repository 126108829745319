import React, { useState, useEffect } from "react";
import getCsrfToken from "./CSRF";


export default function CPlayers(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState([]); //array of players

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()

  useEffect(() => {
    fetch("/api/cteamplayers/" + props.team.id)
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setPlayers(result);
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, [props])

  async function removePlayer(item) {

    return fetch('/api/cremoveplayer', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
      body: JSON.stringify(item)
    })
    .then(data => {
      props.setNewPlayer(props.newPlayer + 1);
      return data.json();
    })

  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

    return (
      <>
        {players.map(player => (
          <div class="mt-2 flex justify-between" key={player.id}>
            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {props.setPlayer(player); props.setView('c-videos')}}

            >
              {player.name}
            </button>

            <button
              class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {removePlayer({"team": props.team.id,"player" : player.id})}}

            >
              Remove
            </button>
          </div>
        ))}
      </>
    );

  }
}

