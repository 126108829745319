import React, { useState } from "react";
import getCsrfToken from "./CSRF";

export default function FormNewPassword(props) {

  const [oldPassword, setOldPassword] = useState();
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [badSet, setBadSet] = useState(false);

async function changePassword(credentials) {
 return fetch('/api/password_change/', {
   method: 'POST',
   headers: {
//     'Content-Type': 'application/json',
     'X-CSRFToken': await getCsrfToken()
   },
//   body: JSON.stringify(credentials)
   body: credentials
 })
   .then(data => {
     if(data.redirected){
       props.setView("c-settings");
       setBadSet(false);
     } else {
       setBadSet(true);
     }
     return data.redirected
   })
}


  const handleSubmit = async e => {
    e.preventDefault();

    const credentials  = new FormData();

    credentials.append("old_password", oldPassword);
    credentials.append("new_password1", newPassword1);
    credentials.append("new_password2", newPassword2);

    const token = await changePassword(credentials);

    console.log(token);
  }


  return (
    <form onSubmit={handleSubmit}>

      <label for="oldpassword" class="block text-sm font-medium text-gray-700">
        Old Password
      </label>
      <div class="mt-1">
        <input type="password" name="oldpassword"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setOldPassword(e.target.value)}
        />
      </div>



      <label for="password1" class="block text-sm font-medium text-gray-700">
        New Password
      </label>
      <div class="mt-1">
        <input type="password" name="password1"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setNewPassword1(e.target.value)}
        />
      </div>

      <label for="password1" class="block text-sm font-medium text-gray-700">
        Verify New Password
      </label>
      <div class="mt-1">
        <input type="password" name="password2"
          class="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setNewPassword2(e.target.value)}
        />
      </div>
      {badSet &&
        <p>Bad Password or New passwords do not match</p>
      }

      <div class="mt-2 flex justify-center">
        <button
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="submit"
        >
          Change Password
        </button>
      </div>
    </form>
  );
}
