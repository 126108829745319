import React, { useState, useEffect } from "react";
import getCsrfToken from "./CSRF";

export default function DeleteTeamForm(props) {



  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [teams, setTeams] = useState([]); //the array of teams


  async function deleteTeam(id) {

    let remainingTeams = teams.filter((team) => id !== team.id);
    setTeams(remainingTeams);



    return fetch('/api/deleteteam', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': await getCsrfToken()
      },
      body: JSON.stringify({"id": id})
    })
    .then(data => {
      return data.json();
    })

  }




  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/coachteams")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setTeams(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [props])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {


    return (
      <>
        {teams.map(team => (
          <button
            key={team.id}
            class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="button"
            onClick={() => {deleteTeam(team.id)}}
          >
           Delete {team.name}
          </button>
        ))}
      </>
    );
  }
}

